import { beginCompleteMigration } from "../app/migrationSlice";
import { ModalType, showModal } from "../app/modalSlice";
import { goBack } from "../app/navigationSlice";
import { store } from "../app/store";
import { getPlatform, isNative } from "./system";

type EventData = {
  type: string;
  payload?: string;
};

if (isNative()) {
  window.onmessage = (event: MessageEvent) => {
    let eventData: EventData | null = null;
    // is event.data a string?
    if (typeof event.data === "string") {
      eventData = { type: event.data };
    } else {
      eventData = event.data as EventData;
    }
    switch (eventData.type) {
      case "backButtonPressed":
        store.dispatch(goBack());
        break;

      case "infoButtonPressed":
        store.dispatch(showModal(ModalType.Info));
        break;

      case "completeMigration":
        store.dispatch(
          beginCompleteMigration(JSON.parse(atob(eventData.payload!)))
        );
        break;
    }
  };
}

type NativeBridgeMessage =
  | "locationChanged"
  | "finishGame"
  | "startMigration"
  | "shareResult";

export type NativeBridgeMessageHandlers = Record<
  NativeBridgeMessage,
  (payload?: unknown) => void
>;

const androidBridgeMessages: NativeBridgeMessageHandlers = {
  finishGame: () => {
    window?.Android?.finishGame();
  },
  locationChanged: (payload: unknown) => {
    window?.Android?.locationChanged(payload as string);
  },
  shareResult: (payload: unknown) => {
    window?.Android?.shareResult(payload as string);
  },
  startMigration: () => {
    window?.Android?.startMigration();
  },
};

const iosBridgeMessages: NativeBridgeMessageHandlers = {
  finishGame: () => {
    window?.webkit?.messageHandlers?.finishGame?.postMessage(undefined);
  },
  locationChanged: (payload: unknown) => {
    window?.webkit?.messageHandlers?.locationChanged?.postMessage(payload);
  },
  shareResult: (payload: unknown) => {
    window?.webkit?.messageHandlers?.shareResult?.postMessage(payload);
  },
  startMigration: () => {
    window?.webkit?.messageHandlers?.startMigration?.postMessage(undefined);
  },
};

export const postNativeBridgeMessage = (
  message: NativeBridgeMessage,
  payload?: unknown
) => {
  if (isNative()) {
    const platform = getPlatform();

    let bridgeMessages: NativeBridgeMessageHandlers | undefined;

    switch (platform) {
      case "ios":
        bridgeMessages = iosBridgeMessages;
        break;
      case "android":
        bridgeMessages = androidBridgeMessages;
        break;
    }

    if (bridgeMessages) {
      bridgeMessages[message](payload);

      console.log("Posting message to native app", message);
    } else {
      console.error("No bridge messages for platform", platform);
    }
  }
};
