import { useEffect, useState } from "react";
import Key from "./Key";
import { isVowel } from "../helpers/letters";
import { useAppDispatch, useAppSelector } from "../hooks";
import { closeGuide, selectShowGuide } from "../app/learningSlice";

const keys = [
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
  ["Z", "X", "C", "V", "B", "N", "M"],
];

interface Props {
  onKeyPress: (key: string) => void;
  disabled: boolean;
  disableConsonants: boolean;
  gameOver?: boolean;
}

const getIndentForRow = (keyboardRowIndex: number) => {
  const baseClassName = "mx-auto";

  switch (keyboardRowIndex) {
    case 0:
      return baseClassName;
    case 1:
      return `${baseClassName} w-[90%]`;
    case 2:
      return `${baseClassName} w-[80%]`;
  }
};

const isConsonant = (char: string) => !isVowel(char.toLowerCase());

const getLetter = (event: KeyboardEvent) => {
  if (event.key.length > 1) {
    return;
  }

  const key = event.key.toLowerCase();

  const keyIsLetter = key >= "a" && key <= "z";

  if (keyIsLetter) {
    return key;
  }

  return undefined;
};

const Keyboard = ({ onKeyPress, disabled, disableConsonants }: Props) => {
  const [downKey, setDownKey] = useState<string | undefined>();
  const showGuide = useAppSelector(selectShowGuide);
  const dispatch = useAppDispatch();

  // Track any key presses
  useEffect(() => {
    const keyUp = (event: KeyboardEvent) => {
      if (showGuide) {
        if (event.key === "Escape" || event.key === "Enter") {
          dispatch(closeGuide());
        }
        return;
      }

      if (disabled) {
        return;
      }

      if (disableConsonants && isConsonant(event.key)) {
        return;
      }

      if (event.target instanceof HTMLInputElement) {
        setDownKey(undefined);
        return;
      }

      const key = getLetter(event);

      if (key) {
        onKeyPress(key);
      }

      setDownKey(undefined);
    };

    const keyDown = (event: KeyboardEvent) => {
      if (showGuide) {
        return;
      }

      if (disabled) {
        return;
      }

      if (disableConsonants && isConsonant(event.key)) {
        return;
      }

      const key = getLetter(event);

      setDownKey(key);
    };

    document.addEventListener("keyup", keyUp);
    document.addEventListener("keydown", keyDown);

    return () => {
      document.removeEventListener("keyup", keyUp);
      document.removeEventListener("keydown", keyDown);
    };
  }, [onKeyPress, disableConsonants, disabled, dispatch, showGuide]);

  return (
    <div className="text-center mw sm:text-xl md:text-2xl">
      {keys.map((_, keyboardRowIndex) => (
        <div
          key={keyboardRowIndex}
          className={`flex ${getIndentForRow(keyboardRowIndex)}`}
        >
          <>
            {keys[keyboardRowIndex].map((key) => (
              <Key
                disabled={disabled || (disableConsonants && isConsonant(key))}
                character={key}
                key={key}
                onKeyPress={onKeyPress}
                isPressed={downKey === key.toLowerCase()}
                height="normal"
              />
            ))}
          </>
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
